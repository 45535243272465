import * as React from "react";

export const DailyPlanSMIcon = (props) => (
  <svg height={48} viewBox="0 0 77 109.202" {...props}>
    <g xmlns="http://www.w3.org/2000/svg">
      <path d="m56.767 31.367-13.435 5.416v37.66l15.162-7.72a2.066 2.066 0 0 0 1.13-1.855v-31.56c0-1.472-1.493-2.496-2.857-1.941Z" />
      <path d="M65.233 5.99 57.79 2.983a40.843 40.843 0 0 0-30.644 0L8.956 10.32 0 13.944v75.789l5.608 3.923 15.226 10.663 3.924 2.75 1.109-.575L43.311 97.6l16.292-8.295 4.82-2.452a21.389 21.389 0 0 0 11.664-19.065V22.133A17.422 17.422 0 0 0 65.21 5.99h.022Zm8.764 61.394c0 6.056-3.39 11.6-8.786 14.352l-5.587 2.857-16.292 8.296-17.444 8.892v-76.79l17.444-7.059 11.793-4.755c9.02-3.647 18.872 3.006 18.872 12.73v41.477Z" />
    </g>
  </svg>
);
