import PouchDB from "pouchdb";
import {
  APP_STATE,
  generateBlankDailyPlan,
  getBlankDailyPlannerState,
} from "../state/default-state";
import { getDateKey } from "../state/utils";

export const DAILY_PLANNER_DOCUMENT_KEY = "myDailyPlanner"; //required for retrieveing a document later from pouchDB

export function createDatabase() {
  const db = new PouchDB("my-daily-planner");
  return db;
}

export async function getInitialState(db) {
  const todaysDate = new Date();
  try {
    const initialDailyPlannerState = await db.get(DAILY_PLANNER_DOCUMENT_KEY);
    const todaysDateKey = getDateKey(todaysDate);
    if (
      initialDailyPlannerState[APP_STATE.CURRENT_DATE_KEY] !== todaysDateKey
    ) {
      initialDailyPlannerState[APP_STATE.CURRENT_DATE_KEY] = todaysDateKey;
      let dayPlans = initialDailyPlannerState[APP_STATE.DAY_PLANS];
      if (!dayPlans.hasOwnProperty(todaysDateKey)) {
        dayPlans = {
          ...dayPlans,
          ...generateBlankDailyPlan(todaysDateKey),
        };
        initialDailyPlannerState[APP_STATE.DAY_PLANS] = dayPlans;
      }
    }
    return initialDailyPlannerState;
  } catch (e) {
    console.log("Initializing your personal daily planner!");
    const initialState = getBlankDailyPlannerState(todaysDate);
    return initialState;
  }
}

export async function saveStateInDatabase(db, state) {
  try {
    const val = await db.get(DAILY_PLANNER_DOCUMENT_KEY);
    state._rev = val._rev;
    await db.put(state);
  } catch (e) {
    if (e.message === "missing") {
      await db.put(state); // Create the document without the _rev since it does not exist
    } else {
      console.error(e);
    }
  }
}
