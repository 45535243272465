import { Flex, TextInput, Title } from "@mantine/core";
import { useDailyPlanner } from "../../hooks/useDailyPlanner";
import { SECTIONS } from "../../state/default-state";
import { getCurrentDayPlan } from "../../state/utils";
import "./MealPlanner.css";
import { WaterIntakeTracker } from "./WaterIntakeTracker/WaterIntakeTracker.js";

export function MealPlanner({ label = "Meal Planner" }) {
  const { privacyMode, state, updateMealPlan } = useDailyPlanner();
  const { dayPlan } = getCurrentDayPlan(state);
  const mealPlan = dayPlan[SECTIONS.MEAL_PLAN];

  const onChange = (meal, value) => {
    const newMealPlan = mealPlan.map((plan) => {
      if (plan.label === meal) {
        plan.value = value;
      }
      return plan;
    });
    updateMealPlan(newMealPlan);
  };

  return (
    <div>
      <Title order={2} size="h4" mt="xl">
        {label}
      </Title>
      <Flex direction={"column"} gap={"md"} mt="md">
        {mealPlan.map((plan) => {
          return (
            <Flex key={plan.label}>
              <TextInput
                className={privacyMode ? "privacyMode" : null}
                aria-label={plan.value}
                autoComplete="off"
                id={plan.label}
                label={plan.label}
                onChange={(e) => {
                  onChange(plan.label, e.currentTarget.value);
                }}
                size="lg"
                style={{ flexGrow: 1 }}
                value={plan.value}
              ></TextInput>
            </Flex>
          );
        })}
        <WaterIntakeTracker />
      </Flex>
    </div>
  );
}
