import { Textarea, Title } from "@mantine/core";
import { useDailyPlanner } from "../../hooks/useDailyPlanner";
import { SECTIONS } from "../../state/default-state";
import { getCurrentDayPlan } from "../../state/utils";
import "./Goal.css";

export function Goal({ label = "Personal Goal" }) {
  const { privacyMode, state, updateGoal } = useDailyPlanner();
  const { dayPlan } = getCurrentDayPlan(state);
  const currentGoal = dayPlan[SECTIONS.GOAL];

  return (
    <div>
      <Title order={2} size="h4" mt="xl">
        {label}
      </Title>
      <Textarea
        className={privacyMode ? "privacyMode" : null}
        mt="md"
        minRows={3}
        maxRows={3}
        maxLength={200}
        size="lg"
        value={currentGoal}
        onChange={(e) => {
          updateGoal(e.currentTarget.value);
        }}
      ></Textarea>
    </div>
  );
}
