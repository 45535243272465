export class PriorityModel {
  constructor(priority, ref = null) {
    this.priority = priority;
    this.ref = ref;
  }

  togglePriotityCompleteness() {
    this.priority.completed = !this.priority.completed;
  }

  editDescription(description = "") {
    this.priority.description = description;
  }
}
