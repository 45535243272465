import { DAILY_PLANNER_DOCUMENT_KEY } from "../db/db";
import { getDateKey } from "./utils";

export const APP_STATE = {
  CURRENT_DATE_KEY: "currentDateKey",
  DAY_PLANS: "dayPlans",
  THEME: "theme",
  PRIVACY_MODE: 'privacyMode'
};

export const SECTIONS = {
  TOP_3_PRIORITIES: "top3",
  TODO_LIST: "todo",
  GOAL: "goal",
  MEAL_PLAN: "meal",
  WATER_INTAKE: "waterIntake",
  MOOD: "mood",
};

function generateBlankPriorities(n = 3) {
  const result = [];
  for (let i = 0; i < n; i++) {
    const priority = { id: i, completed: false, description: "" };
    result.push(priority);
  }
  return result;
}

function generateInitialMealPlan() {
  const initialObject = [
    { label: "Breakfast", value: "" },
    { label: "Lunch", value: "" },
    { label: "Dinner", value: "" },
    { label: "Snacks", value: "" },
  ];
  return Object.create(initialObject);
}

function generateEmptyGlasses(n = 8) {
  const result = [];
  for (let i = 0; i < n; i++) {
    result.push({ id: i, filled: false, description: i });
  }
  return result;
}

export function generateBlankDailyPlan(dateKey) {
  const newPlan = {
    [dateKey]: {
      [SECTIONS.TOP_3_PRIORITIES]: [...generateBlankPriorities(3)],
      [SECTIONS.TODO_LIST]: [...generateBlankPriorities(8)],
      [SECTIONS.GOAL]: "",
      [SECTIONS.MEAL_PLAN]: [...generateInitialMealPlan()],
      [SECTIONS.WATER_INTAKE]: [...generateEmptyGlasses(8)],
      [SECTIONS.MOOD]: null,
    },
  };
  return newPlan;
}

export function getBlankDailyPlannerState(date) {
  const currentDateKey = getDateKey(date);
  const initialState = {
    _id: DAILY_PLANNER_DOCUMENT_KEY,
    [APP_STATE.CURRENT_DATE_KEY]: currentDateKey,
    [APP_STATE.DAY_PLANS]: generateBlankDailyPlan(currentDateKey),
    [APP_STATE.THEME]: COLOR_PALETTE[0],
  };
  return {
    ...initialState,
  };
}

export const COLOR_PALETTE = [
  "#FFF",
  "#FAEDCB",
  "#F2D8D8",
  "#C6DEF1",
  "#ACB1D6",
  "#BFCCB5",
];

export const AppName = "DailyPlan";
