import { AppShell, Flex, Grid, Header } from "@mantine/core";
import { useDailyPlanner } from "../../hooks/useDailyPlanner";
import { SECTIONS } from "../../state/default-state";
import { About } from "../About/About";
import { DateSwitcher } from "../DateSwitcher/DateSwitcher";
import { Goal } from "../Goal/Goal";
import { DailyPlanIcon } from "../Icons/DailyPlanIcon";
import { DailyPlanSMIcon } from "../Icons/DailyPlanSMIcon";
import { MealPlanner } from "../MealPlanner/MealPlanner";
import { MoodTracker } from "../MoodTracker/MoodTracker";
import { Priorities } from "../Priorities/Priorities";
import { PrivacyModeToggle } from "../PrivacyModeToggle/PrivacyModeToggle";
import { ThemePicker } from "../ThemePicker/ThemePicker";
import "./DailyPlanner.css";

const GAP = "12px";

export function DailyPlanner() {
  const { theme } = useDailyPlanner();

  return (
    <AppShell
      px="36px"
      header={
        <Header height={75} px="48px" style={{ backgroundColor: theme }}>
          <Flex align={"center"} mih={75} justify={"space-between"}>
            <Logo />
            <Flex align={"center"}>
              <DateSwitcher />
              <ThemePicker />
              <PrivacyModeToggle />
              <About />
            </Flex>
          </Flex>
        </Header>
      }
    >
      <AppContent />
    </AppShell>
  );
}

function AppContent() {
  const { theme } = useDailyPlanner();

  return (
    <Grid
      grow
      gutter="36px"
      gutterMd="72px"
      gutterLg="84px"
      gutterXl="96px"
      columns={12}
      style={{ backgroundColor: theme }}
    >
      <Grid.Col md={6} sm={12}>
        <Flex direction={"column"} gap={GAP}>
          <Priorities
            label="Top 3 Priorities"
            count={3}
            sectionKey={SECTIONS.TOP_3_PRIORITIES}
          />
          <Priorities
            label="Todo List"
            count={8}
            sectionKey={SECTIONS.TODO_LIST}
          />
        </Flex>
      </Grid.Col>
      <Grid.Col md={6} sm={12}>
        <Flex direction={"column"} gap={GAP}>
          <Goal />
          <MealPlanner />
          <MoodTracker />
        </Flex>
      </Grid.Col>
    </Grid>
  );
}

function Logo() {
  return (
    <div className="logo">
      <DailyPlanIcon className="logo visible-lg" />
      <DailyPlanSMIcon className="logo visible-sm" />
    </div>
  );
}
