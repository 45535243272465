import { ActionIcon, Flex, Text } from "@mantine/core";
import { FiDroplet } from "react-icons/fi";
import { useDailyPlanner } from "../../../hooks/useDailyPlanner";
import { SECTIONS } from "../../../state/default-state";
import { getCurrentDayPlan } from "../../../state/utils";
import "./WaterIntakeTracker.css";

export function WaterIntakeTracker({ label = "Water Intake Tracker" }) {
  const { state, updateWaterIntake, theme } = useDailyPlanner();
  const { dayPlan } = getCurrentDayPlan(state);
  const glasses = dayPlan[SECTIONS.WATER_INTAKE];

  const onChange = (glass) => {
    const waterIntake = [...glasses];
    for (let i = 0; i < waterIntake.length; i++) {
      if (i === 0 && glass.id === waterIntake[i].id && glass.filled === true) {
        glass.filled = false;
      } else if (waterIntake[i].id <= glass.id) {
        waterIntake[i].filled = true;
      } else {
        waterIntake[i].filled = false;
      }
    }
    updateWaterIntake(waterIntake);
  };

  return (
    <div>
      <Text fz="lg" fw={500}>
        {label}
      </Text>
      <Flex gap={"6px"} align="center">
        {glasses.map((glass) => {
          return (
            <ActionIcon
              color="dark"
              key={glass.id}
              variant={glass.filled ? "filled" : "subtle"}
              size="lg"
              onClick={() => onChange(glass)}
            >
              {glass.filled ? (
                <FiDroplet stroke={theme} size="24" />
              ) : (
                <FiDroplet size="24" />
              )}
            </ActionIcon>
          );
        })}
      </Flex>
    </div>
  );
}
