import { addDays, subDays, format } from "date-fns";
import { useDailyPlanner } from "./useDailyPlanner";
import { APP_STATE } from "../state/default-state";
import { getCurrentDateFromDateKey, getDateKey } from "../state/utils";

export const DATE_DIRECTION = {
  TODAY: 0,
  INCREMENT: 1,
  DECREMENT: -1,
};

export function useCurrentDate() {
  const { state, updateCurrentDateKey } = useDailyPlanner();
  const currentDateKey = state[APP_STATE.CURRENT_DATE_KEY];
  const currentDate = getCurrentDateFromDateKey(currentDateKey);

  function updateDate(direction) {
    if (direction === DATE_DIRECTION.INCREMENT) {
      updateCurrentDateKey(getDateKey(addDays(currentDate, 1)));
    } else if (direction === DATE_DIRECTION.DECREMENT) {
      updateCurrentDateKey(getDateKey(subDays(currentDate, 1)));
    } else if (direction === DATE_DIRECTION.TODAY) {
      const todaysDateKey = getDateKey(new Date());
      if (todaysDateKey === currentDateKey) {
        return;
      } else {
        updateCurrentDateKey(getDateKey(new Date()));
      }
    } else {
      return;
    }
  }

  return {
    formattedCurrentDate: format(new Date(currentDate), "ccc - PP"),
    formattedShortCurrentDate: format(new Date(currentDate), "LLL d, yy"),
    updateDate,
  };
}
