import { ActionIcon, Flex, Title } from "@mantine/core";
import { FiFrown, FiMeh, FiSmile } from "react-icons/fi";
import { useDailyPlanner } from "../../hooks/useDailyPlanner";
import { SECTIONS } from "../../state/default-state";
import "./MoodTracker.css";
import { getCurrentDayPlan } from "../../state/utils";

export const MoodModel = {
  SAD: "sad",
  MEH: "meh",
  HAPPY: "happy",
};

export function MoodTracker({ label = "Mood Tracker" }) {
  const { state, updateMood, theme } = useDailyPlanner();
  const { dayPlan } = getCurrentDayPlan(state);
  const currentMood = dayPlan[SECTIONS.MOOD];

  const onToggle = (model) => {
    if (currentMood === model) {
      updateMood(null);
    } else {
      updateMood(model);
    }
  };

  return (
    <div>
      <Title order={2} size="h4" mt="xl">
        {label}
      </Title>
      <Flex gap={"6px"} align="center" mt="md">
        <ActionIcon
          color="dark"
          variant={currentMood === MoodModel.HAPPY ? "filled" : "subtle"}
          size="lg"
          onClick={() => onToggle(MoodModel.HAPPY)}
        >
          {currentMood === MoodModel.HAPPY ? (
            <FiSmile size="24" stroke={theme} />
          ) : (
            <FiSmile size="24" />
          )}
        </ActionIcon>
        <ActionIcon
          color="dark"
          variant={currentMood === MoodModel.MEH ? "filled" : "subtle"}
          size="lg"
          onClick={() => onToggle(MoodModel.MEH)}
        >
          {currentMood === MoodModel.MEH ? (
            <FiMeh size="24" stroke={theme} />
          ) : (
            <FiMeh size="24" />
          )}
        </ActionIcon>
        <ActionIcon
          color="dark"
          variant={currentMood === MoodModel.SAD ? "filled" : "subtle"}
          size="lg"
          onClick={() => onToggle(MoodModel.SAD)}
        >
          {currentMood === MoodModel.SAD ? (
            <FiFrown size="24" stroke={theme} />
          ) : (
            <FiFrown size="24" />
          )}
        </ActionIcon>
      </Flex>
    </div>
  );
}
