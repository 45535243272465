import { ActionIcon, Flex, Popover, ColorSwatch } from "@mantine/core";
import { useClickOutside, useDisclosure } from "@mantine/hooks";
import { MdOutlineColorLens } from "react-icons/md";
import { COLOR_PALETTE } from "../../state/default-state";
import { useDailyPlanner } from "../../hooks/useDailyPlanner";
import { FiCheck } from "react-icons/fi";

export function ThemePicker() {
  const { theme, updateTheme } = useDailyPlanner();
  const [opened, { close, open }] = useDisclosure(false);
  const ref = useClickOutside(() => close());

  const onThemeChange = (color) => {
    updateTheme(color);
    close();
  };

  return (
    <Popover width={96} position={"bottom-left"} opened={opened} trapFocus>
      <Popover.Target>
        <ActionIcon
          color="dark"
          size="md"
          title="Theme"
          ml={"12px"}
          onClick={() => {
            opened ? close() : open();
          }}
        >
          <MdOutlineColorLens size={24} />
        </ActionIcon>
      </Popover.Target>
      <div ref={ref}>
        <Popover.Dropdown style={{ backgroundColor: theme }}>
          <Flex wrap="wrap" gap="6px" justify={"space-around"}>
            {COLOR_PALETTE.map((color) => {
              return (
                <ActionIcon
                  key={color}
                  onClick={() => onThemeChange(color)}
                  variant="transparent"
                >
                  <ColorSwatch
                    style={{
                      flexShrink: 0,
                    }}
                    color={color}
                  >
                    {theme === color ? (
                      <FiCheck size={18} strokeWidth={3} />
                    ) : null}
                  </ColorSwatch>
                </ActionIcon>
              );
            })}
          </Flex>
        </Popover.Dropdown>
      </div>
    </Popover>
  );
}
