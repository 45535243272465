import { useDebounce } from "@uidotdev/usehooks";
import { createContext, useContext, useEffect, useState } from "react";
import { saveStateInDatabase } from "../db/db";
import {
  APP_STATE,
  COLOR_PALETTE,
  SECTIONS,
  generateBlankDailyPlan,
} from "../state/default-state";
import { getCurrentDayPlan, updateRootBackground } from "../state/utils";

const DailyPlannerContext = createContext();

const useDailyPlanner = () => useContext(DailyPlannerContext);

const DailyPlannerProvider = ({ dbState, initialState, children }) => {
  const [dailyPlanner, setDailyPlanner] = useState({
    state: initialState,
    modified: false,
    privacyMode: false,
  });
  const { state, modified, privacyMode } = dailyPlanner;
  const debouncedState = useDebounce(state, 300);
  const { db } = dbState;
  const theme = state[APP_STATE.THEME] ?? COLOR_PALETTE[0];

  useEffect(() => {
    async function saveToDB(db) {
      if (debouncedState) {
        await saveStateInDatabase(db, debouncedState);
      }
    }
    if (db && modified) {
      saveToDB(db);
    }
  }, [debouncedState, db, modified]);

  const updateCurrentDateKey = (dateKey) => {
    const { dayPlans } = getCurrentDayPlan(state, dateKey);

    let newState = {};
    if (dayPlans.hasOwnProperty(dateKey)) {
      newState = {
        ...state,
        [APP_STATE.CURRENT_DATE_KEY]: dateKey,
      };
    } else {
      const newDayPlans = {
        ...dayPlans,
        ...generateBlankDailyPlan(dateKey),
      };
      newState = {
        ...state,
        [APP_STATE.CURRENT_DATE_KEY]: dateKey,
        [APP_STATE.DAY_PLANS]: newDayPlans,
      };
    }
    setDailyPlanner({ state: newState, modified: true, privacyMode });
  };

  const updatePriorities = (sectionKey, priorities) => {
    const { dateKey, dayPlan, dayPlans } = getCurrentDayPlan(state);
    if (dayPlan) {
      const newDayPlan = { ...dayPlan };
      newDayPlan[sectionKey] = priorities;
      const newDayPlans = {
        ...dayPlans,
        [dateKey]: newDayPlan,
      };
      const newState = {
        ...state,
        [APP_STATE.DAY_PLANS]: newDayPlans,
      };
      setDailyPlanner({ state: newState, modified: true, privacyMode });
    } else {
      console.error("Couldn't find the daily plan for date", dateKey);
    }
  };

  const updateMood = (mood) => {
    const { dateKey, dayPlan, dayPlans } = getCurrentDayPlan(state);
    if (dayPlan) {
      const newDayPlan = { ...dayPlan };
      newDayPlan[SECTIONS.MOOD] = mood;
      const newDayPlans = {
        ...dayPlans,
        [dateKey]: newDayPlan,
      };
      const newState = {
        ...state,
        [APP_STATE.DAY_PLANS]: newDayPlans,
      };
      setDailyPlanner({ state: newState, modified: true, privacyMode });
    } else {
      console.error("Couldn't find the daily plan for date", dateKey);
    }
  };

  const updateGoal = (goal) => {
    const { dateKey, dayPlan, dayPlans } = getCurrentDayPlan(state);
    if (dayPlan) {
      const newDayPlan = { ...dayPlan };
      newDayPlan[SECTIONS.GOAL] = goal;
      const newDayPlans = {
        ...dayPlans,
        [dateKey]: newDayPlan,
      };
      const newState = {
        ...state,
        [APP_STATE.DAY_PLANS]: newDayPlans,
      };
      setDailyPlanner({ state: newState, modified: true, privacyMode });
    } else {
      console.error("Couldn't find the daily plan for date", dateKey);
    }
  };

  const updateMealPlan = (mealPlan) => {
    const { dateKey, dayPlan, dayPlans } = getCurrentDayPlan(state);
    if (dayPlan) {
      const newDayPlan = { ...dayPlan };
      newDayPlan[SECTIONS.MEAL_PLAN] = mealPlan;
      const newDayPlans = {
        ...dayPlans,
        [dateKey]: newDayPlan,
      };
      const newState = {
        ...state,
        [APP_STATE.DAY_PLANS]: newDayPlans,
      };
      setDailyPlanner({ state: newState, modified: true, privacyMode });
    } else {
      console.error("Couldn't find the daily plan for date", dateKey);
    }
  };

  const updateWaterIntake = (waterIntake) => {
    const { dateKey, dayPlan, dayPlans } = getCurrentDayPlan(state);
    if (dayPlan) {
      const newDayPlan = { ...dayPlan };
      newDayPlan[SECTIONS.WATER_INTAKE] = waterIntake;
      const newDayPlans = {
        ...dayPlans,
        [dateKey]: newDayPlan,
      };
      const newState = {
        ...state,
        [APP_STATE.DAY_PLANS]: newDayPlans,
      };
      setDailyPlanner({ state: newState, modified: true, privacyMode });
    } else {
      console.error("Couldn't find the daily plan for date", dateKey);
    }
  };

  const updatePrivacyMode = (mode) => {
    setDailyPlanner({ state, modified, privacyMode: mode });
  };

  const updateTheme = (color) => {
    const newState = {
      ...state,
      [APP_STATE.THEME]: color,
    };
    setDailyPlanner({ state: newState, modified: true, privacyMode });
    updateRootBackground(color);
  };

  return (
    <DailyPlannerContext.Provider
      value={{
        privacyMode,
        state,
        theme,
        updateCurrentDateKey,
        updateGoal,
        updateMealPlan,
        updateMood,
        updatePriorities,
        updatePrivacyMode,
        updateTheme,
        updateWaterIntake,
      }}
    >
      {children}
    </DailyPlannerContext.Provider>
  );
};

export { DailyPlannerProvider, useDailyPlanner };
