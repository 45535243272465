import { MantineProvider, LoadingOverlay } from "@mantine/core";
import "./App.css";
import { DailyPlanner } from "./components/DailyPlanner/DailyPlanner";
import { DailyPlannerProvider } from "./hooks/useDailyPlanner";
import { useEffect, useState } from "react";
import { createDatabase, getInitialState } from "./db/db";
import { BORDER_COLOR, updateRootBackground } from "./state/utils";
import { APP_STATE } from "./state/default-state";
import { Analytics } from "@vercel/analytics/react";

function App() {
  const [dbState, setDbState] = useState(null);
  const [initialState, setInitialState] = useState(null);

  useEffect(() => {
    async function initializeDatabase() {
      const db = createDatabase();
      const initialDailyPlannerState = await getInitialState(db);
      setInitialState(initialDailyPlannerState);
      setDbState({ databaseInitialized: true, db });
      updateRootBackground(initialDailyPlannerState[APP_STATE.THEME]);
    }
    if (!dbState) {
      initializeDatabase();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!dbState || !dbState.databaseInitialized) {
    return <LoadingOverlay visible={true} overlayBlur={2} />;
  } else {
    return (
      <MantineProvider
        inherit
        theme={{
          components: {
            Checkbox: {
              styles: (theme) => ({
                input: {
                  backgroundColor: "transparent",
                  borderColor: BORDER_COLOR,
                },
              }),
            },
            TextArea: {
              styles: (theme) => ({
                input: {
                  backgroundColor: "transparent",
                  borderColor: BORDER_COLOR,
                },
              }),
            },
            Input: {
              styles: (theme) => ({
                input: {
                  backgroundColor: "transparent",
                  borderColor: BORDER_COLOR,
                },
              }),
            },
            Header: {
              styles: (theme) => ({
                root: {
                  borderColor: BORDER_COLOR,
                },
              }),
            },
            Popover: {
              styles: (theme) => ({
                dropdown: {
                  borderColor: BORDER_COLOR,
                },
              }),
            },
            Modal: {
              styles: (theme) => ({
                inner: {
                  left: "50%",
                  transform: "translateX(-50%)",
                },
              }),
            },
          },
        }}
      >
        <DailyPlannerProvider initialState={initialState} dbState={dbState}>
          <DailyPlanner />
        </DailyPlannerProvider>
        <Analytics />
      </MantineProvider>
    );
  }
}

export default App;
