import { ActionIcon, Flex, Text } from "@mantine/core";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { BiCircle } from "react-icons/bi";
import { DATE_DIRECTION, useCurrentDate } from "../../hooks/useCurrentDate";
import "./DateSwitcher.css";

export function DateSwitcher() {
  const { formattedCurrentDate, formattedShortCurrentDate, updateDate } =
    useCurrentDate();

  return (
    <Flex align="center">
      <ActionIcon
        color="dark"
        size="md"
        title="Previous Day"
        variant="subtle"
        onClick={() => {
          updateDate(DATE_DIRECTION.DECREMENT);
        }}
      >
        <FiChevronLeft size={24} strokeWidth={2} />
      </ActionIcon>
      <ActionIcon
        color="dark"
        size="md"
        title="Today"
        variant="subtle"
        onClick={() => {
          updateDate(DATE_DIRECTION.TODAY);
        }}
      >
        <BiCircle size={12} strokeWidth={2} />
      </ActionIcon>
      <ActionIcon
        color="dark"
        size="md"
        title="Next Day"
        variant="subtle"
        onClick={() => {
          updateDate(DATE_DIRECTION.INCREMENT);
        }}
      >
        <FiChevronRight size={24} strokeWidth={2} />
      </ActionIcon>
      <Text
        fz="lg"
        fw={500}
        style={{ width: "170px", textAlign: "center" }}
        className="visible-lg"
      >
        {formattedCurrentDate}
      </Text>
      <Text
        fz="lg"
        fw={500}
        style={{ width: "90px", textAlign: "center", whiteSpace: "nowrap" }}
        className="visible-sm"
      >
        {formattedShortCurrentDate}
      </Text>
    </Flex>
  );
}
