import { Checkbox, Divider, Flex, TextInput, Title } from "@mantine/core";
import { createRef } from "react";
import { useDailyPlanner } from "../../hooks/useDailyPlanner";
import { SECTIONS } from "../../state/default-state";
import { BORDER_COLOR, getCurrentDayPlan } from "../../state/utils";
import "./Priorities.css";
import { PriorityModel } from "./Priorities.model";

function generatePriorityRefMap(priorities) {
  const result = [];
  for (let i = 0; i < priorities.length; i++) {
    result.push(new PriorityModel(priorities[i], createRef()));
  }
  return result;
}

export function Priorities({
  label = "Top Priorities",
  sectionKey = SECTIONS.TOP_3_PRIORITIES,
}) {
  const { privacyMode, state, updatePriorities } = useDailyPlanner();
  const { dayPlan } = getCurrentDayPlan(state);
  const priorities = dayPlan[sectionKey];
  const priorityRefMap = generatePriorityRefMap(priorities);

  const onEnterPressed = (id) => {
    let index = -1;
    priorityRefMap.find((p, i) => {
      if (p.priority.id === id) {
        index = i;
        return true;
      } else {
        return false;
      }
    });
    if (index < priorityRefMap.length - 1) {
      priorityRefMap[index + 1].ref?.current?.focus();
    }
  };

  const onCheck = (id, isChecked) => {
    const newPriorities = [...priorities];
    for (let i = 0; i < newPriorities.length; i++) {
      if (newPriorities[i].id === id) {
        newPriorities[i].completed = isChecked;
      }
    }
    updatePriorities(sectionKey, newPriorities);
  };

  const onDescriptionChange = (id, description) => {
    const newPriorities = [...priorities];
    for (let i = 0; i < newPriorities.length; i++) {
      if (newPriorities[i].id === id) {
        newPriorities[i].description = description;
      }
    }
    updatePriorities(sectionKey, newPriorities);
  };

  return (
    <div>
      <Title order={2} size="h4" mt="xl">
        {label}
      </Title>
      <Flex direction="column" gap="xs" mt="md">
        {priorityRefMap.map((priorityAndRef) => {
          return (
            <div key={priorityAndRef.priority.id}>
              <Priority
                onCheck={onCheck}
                onDescriptionChange={onDescriptionChange}
                onEnterPressed={onEnterPressed}
                priorityAndRef={priorityAndRef}
                privacyMode={privacyMode}
              ></Priority>
              <Divider
                size="xs"
                variant="dashed"
                style={{ borderColor: BORDER_COLOR }}
              ></Divider>
            </div>
          );
        })}
      </Flex>
    </div>
  );
}

function Priority({
  onCheck,
  onDescriptionChange,
  onEnterPressed,
  priorityAndRef,
  privacyMode = false,
}) {
  const id = priorityAndRef.priority.id;
  const checked = priorityAndRef.priority.completed;
  const description = priorityAndRef.priority.description;
  const ref = priorityAndRef.ref;

  return (
    <Flex align="center" gap="xs">
      <Checkbox
        color="dark"
        radius={"xl"}
        size={"md"}
        checked={checked}
        onChange={(e) => onCheck(id, e.currentTarget.checked)}
      />
      <TextInput
        className={privacyMode ? "privacyMode" : null}
        aria-label={description}
        autoComplete="off"
        ref={ref}
        size="lg"
        variant="unstyled"
        value={description}
        onChange={(e) => onDescriptionChange(id, e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onEnterPressed(id);
          }
        }}
        style={{
          flexGrow: "1",
        }}
      />
    </Flex>
  );
}
