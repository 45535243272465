import { ActionIcon, Container, Modal, Text, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { FiInfo } from "react-icons/fi";
import "./About.css";
import { AppName } from "../../state/default-state";
import { useDailyPlanner } from "../../hooks/useDailyPlanner";

export function About() {
  const { theme } = useDailyPlanner();
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <ActionIcon
        color="dark"
        size="md"
        onClick={open}
        title="About"
        ml={"12px"}
      >
        <FiInfo size={24} />
      </ActionIcon>
      <>
        <Modal.Root opened={opened} onClose={close} size="auto">
          <Modal.Overlay />
          <Modal.Content style={{ backgroundColor: theme }}>
            <Modal.Header style={{ backgroundColor: theme }}>
              <Modal.Title>
                <Text fw={700} fz="xl" px="16px">
                  About
                </Text>
              </Modal.Title>
              <Modal.CloseButton />
            </Modal.Header>
            <Modal.Body>
              <AboutContent />
            </Modal.Body>
          </Modal.Content>
        </Modal.Root>
      </>
    </>
  );
}

function AboutContent() {
  return (
    <Container>
      <p>
        Welcome to <b>{AppName}</b>, your ultimate tool for effective day
        planning and productivity. With our simple and intuitive one-page app,
        we aim to help you plan your day effortlessly, boost your efficiency,
        and foster consistency in your daily routine.
      </p>
      <Title order={4} size="h4">
        {" "}
        Streamlined Day Planning
      </Title>
      <p>
        {AppName} features a clean and minimalistic template designed to
        streamline your day planning process. Our carefully crafted sections
        empower you to organize your day with ease and focus on what truly
        matters. The template includes:
      </p>
      <Title order={5} size={"h5"}>
        1. Top 3 Priorities
      </Title>
      <p>
        Identify and prioritize the most important tasks for the day. By setting
        clear objectives, you'll stay focused on what needs to be accomplished.
      </p>
      <Title order={5} size={"h5"}>
        2. Todo List
      </Title>
      <p>
        Create a comprehensive to-do list to keep track of all your tasks and
        ensure nothing falls through the cracks. Stay organized and in control
        of your day.
      </p>
      <Title order={5} size={"h5"}>
        3. Personal Goal
      </Title>
      <p>
        Set a personal goal that aligns with your aspirations and ambitions. Set
        a personal goal that aligns with your aspirations and ambitions. By
        typing your goal daily, you reinforce your commitment and increase your
        motivation to work towards it.
      </p>
      <Title order={5} size={"h5"}>
        4. Meal Planner
      </Title>
      <p>
        Stay organized and in control of your meals with {AppName}'s integrated
        meal planner. Plan your daily meals in advance, ensuring you have a
        clear overview of your dining schedule. Whether you're planning
        breakfast, lunch, dinner, or snacks, DailyPlan helps you stay on track
        and make informed choices for your meals.
      </p>
      <Title order={5} size={"h5"}>
        5. Water Intake Tracker
      </Title>
      <p>
        Stay hydrated throughout the day with our integrated water intake
        tracker. Monitor your water consumption and ensure you meet your daily
        hydration goals.
      </p>
      <Title order={5} size={"h5"}>
        6. Mood Tracker
      </Title>
      <p>
        Track your mood and emotions throughout the day. Gain insights into how
        different activities and circumstances impact your well-being, and make
        adjustments accordingly.
      </p>
      <Title order={4} size={"h4"}>
        Effortless Planning, Consistent Results
      </Title>
      <p>
        At {AppName}, we believe in the power of simplicity. We've created an
        app that focuses on essential features, allowing you to plan your day
        without unnecessary complexity. By making the habit of writing or typing
        a part of your daily routine, you'll reinforce the discipline of
        effective planning.
      </p>
      <Title order={4} size={"h4"}>
        Private, Secure, and Accessible
      </Title>
      <p>
        Rest assured that your data is secure with {AppName}. All information
        you enter is stored locally in your browser, ensuring privacy and
        confidentiality. You are the sole owner of your data, and only you can
        access it. Your peace of mind is our top priority.
      </p>
      <Title order={4} size={"h4"}>
        Start Your Day with {AppName}
      </Title>
      <p>
        Begin each day with intention and purpose. {AppName} empowers you to
        take control of your time, boost productivity, and achieve your goals.
      </p>
      <Title order={4} size={"h4"}>
        Choose {AppName} Today!
      </Title>
    </Container>
  );
}
