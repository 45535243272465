import * as React from "react";

export const DailyPlanIcon = (props) => (
  <svg height={48} viewBox="0 0 369.913 109.202" {...props}>
    <g xmlns="http://www.w3.org/2000/svg">
      <path d="m56.767 31.367-13.435 5.416v37.66l15.162-7.72a2.066 2.066 0 0 0 1.13-1.855v-31.56c0-1.472-1.493-2.496-2.857-1.941Z" />
      <path d="M65.233 5.99 57.79 2.983a40.843 40.843 0 0 0-30.644 0L8.956 10.32 0 13.944v75.789l5.608 3.923 15.226 10.663 3.924 2.75 1.109-.575L43.311 97.6l16.292-8.295 4.82-2.452a21.389 21.389 0 0 0 11.664-19.065V22.133A17.422 17.422 0 0 0 65.21 5.99h.022Zm8.764 61.394c0 6.056-3.39 11.6-8.786 14.352l-5.587 2.857-16.292 8.296-17.444 8.892v-76.79l17.444-7.059 11.793-4.755c9.02-3.647 18.872 3.006 18.872 12.73v41.477Z" />
    </g>
    <path d="m118.65 26.85 8.554 52.227c.658 4.112-8.635 4.523-9.129 1.563l-1.645-9.87h-9.623l-1.645 9.87c-.41 2.467-9.787 2.55-9.129-1.563l8.636-52.226c.411-2.468 13.653-2.221 13.982 0zm-3.783 34.626L111.66 41.82l-3.29 19.657h6.497zm22.207 19.41V26.851c0-2.468 9.376-2.468 9.376 0v54.035c0 2.468-9.376 2.468-9.376 0zm30.267-7.484h11.843c1.645 0 1.645 9.294 0 9.294h-16.531c-4.689 0-4.689 0-4.689-4.524V27.015c0-1.645 9.377-1.645 9.377 0v46.387zm42.932-46.798c.905-2.468 10.363-1.727 8.636 2.385-3.783 9.047-10.528 25.579-10.28 25.167v26.73c0 2.468-9.377 2.468-9.377 0v-26.73L188.89 29.318c-1.892-4.524 7.484-5.428 8.718-2.385l6.333 16.202c1.727-4.605 4.524-11.596 6.333-16.531zm49.101 16.367c-.082 5.264-.822 10.527-6.004 14.393-4.359 3.372-9.787 2.632-14.969 2.632v20.89c0 2.468-9.376 2.468-9.376 0V30.305c0-3.866.082-5.1 5.84-5.182h8.471c2.96 0 6.25.083 8.8 1.728 5.593 3.536 7.32 9.787 7.238 16.12zm-9.376 1.727c.082-.905.082-4.195-.082-5.757-.33-3.043-1.48-4.606-5.264-4.606h-6.25V50.62h7.072c3.784 0 4.36-4.606 4.524-5.922zm27.388 28.704h11.843c1.645 0 1.645 9.294 0 9.294h-16.531c-4.688 0-4.688 0-4.688-4.524V27.015c0-1.645 9.376-1.645 9.376 0v46.387zm43.92-46.551 8.553 52.226c.658 4.112-8.636 4.523-9.13 1.563l-1.644-9.87h-9.623l-1.645 9.87c-.411 2.467-9.787 2.55-9.13-1.563l8.637-52.226c.41-2.468 13.653-2.221 13.982 0zm-3.784 34.625-3.207-19.657-3.29 19.657h6.497zm43.344-5.1V26.852c0-2.468 9.047-2.468 9.047 0v52.39c0 4.113-7.896 4.195-8.718 2.139l-12.337-30.267v29.773c0 2.468-9.13 2.468-9.13 0V29.482c0-6.415 7.814-4.44 9.048-1.973z" />
  </svg>
);
