import { ActionIcon } from "@mantine/core";
import { useDailyPlanner } from "../../hooks/useDailyPlanner";
import { FiEye, FiEyeOff } from "react-icons/fi";

export function PrivacyModeToggle() {
  const { privacyMode, updatePrivacyMode } = useDailyPlanner();

  return (
    <>
      {privacyMode ? (
        <ActionIcon
          color="dark"
          ml={"12px"}
          size="md"
          title="Disable Privacy Mode"
          variant="subtle"
          onClick={() => updatePrivacyMode(false)}
        >
          <FiEyeOff size={24} strokeWidth={2}></FiEyeOff>
        </ActionIcon>
      ) : (
        <ActionIcon
          color="dark"
          ml={"12px"}
          size="md"
          title="Enable Privacy Mode"
          variant="subtle"
          onClick={() => updatePrivacyMode(true)}
        >
          <FiEye size={24} strokeWidth={2}></FiEye>
        </ActionIcon>
      )}
    </>
  );
}
