import { APP_STATE } from "./default-state";

export function getCurrentDayPlan(dailyPlannerState, dateKey = null) {
  if (!dailyPlannerState) {
    throw new Error("Daily Planner state is undefined", dailyPlannerState);
  }
  if (!dateKey) {
    dateKey = dailyPlannerState[APP_STATE.CURRENT_DATE_KEY];
  }

  const dayPlans = dailyPlannerState[APP_STATE.DAY_PLANS];
  const dayPlan = dayPlans[dateKey];
  return { dateKey, dayPlans, dayPlan };
}

export function getDateKey(date) {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

export function getCurrentDateFromDateKey(dateKey) {
  const [year, month, date] = dateKey.split("-");
  const newDate = new Date(year, month - 1, date);
  return new Date(newDate);
}

export const BORDER_COLOR = "#363732";

export function updateRootBackground(color) {
  const root = [...document.body.children].find((elem) => elem.id === "root");
  if (root) {
    root.style.backgroundColor = color;
  }
  document.body.style.backgroundColor = color;
}
